import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css';
import { useState, useEffect, useRef } from "react";
import globals from '../config/globals/globals';
import { _ } from "../config/languages/i18n";
import { auth } from "../firebase";
import { signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { signAction } from "../redux/actions/auth";
import { useDispatch } from "react-redux";
import { Cookies } from "react-cookie";
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";
import axios from "axios";
// const tagManagerArgs={
// 	gtmId: 'GTM-PQCRDTTD',
// 	page: {
// 		url: window.location.href
// 	  }
// }
// TagManager.initialize(tagManagerArgs)
const Start = () => {
    const provider = new GoogleAuthProvider();
    const providerApple = new OAuthProvider('apple.com');
    const [type, setType] = useState("");
    const dispatch = useDispatch();
    const formData = new FormData();
    const [sloganNumber, setSloganNumber] = useState("")
    const cookies = new Cookies()
    const divRef = useRef(null)
    const lang = cookies.get("language")
    const [ip, setIp] = useState('')
    const [device, setDevice] = useState('')
    const [utc, setUtc] = useState('')
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const [instagramBrowser, setInstagramBrowser] = useState(false)

    useEffect(()=>{
		setDevice(navigator.userAgent)
	},[])

    useEffect(()=>{
		setUtc(new Date().getTimezoneOffset()/-60)
	},[])

    useEffect(()=>{
		setInstagramBrowser(device.includes('Instagram'))
	},[device])

	useEffect(()=>{
		HTTP.get('https://api.ipify.org?format=json')
		.then(
			res=>{
				setIp(res.data.ip)
			}
		)
	},[])

    useEffect(() => {
        if (lang == 'tr') {
            const sloganNumber = (Math.floor(Math.random() * 3) + 1);
            setSloganNumber(sloganNumber)
        } else {
            const sloganNumber = (Math.floor(Math.random() * 3) + 4);
            setSloganNumber(sloganNumber)
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop
            })
        }, 200)
    }, [divRef.current])

    useEffect(() => {
        const number = window.location.href.split('/')[5]
        if (number) {
            localStorage.setItem('reference', JSON.stringify(window.location.href))
        } else {
            localStorage.removeItem('reference')
        }
    }, [])

    const googleLogin = async () => {
        const data = await signInWithPopup(auth, provider);
        const credential = GoogleAuthProvider.credentialFromResult(data);
        const token = credential.accessToken;
        const user = data.user;
        const email = user.email;
        setType("");
        formData.append("email", email);
        formData.append("type", 3);
        formData.append("lang", lang);
        formData.append("ip_address", ip);
        formData.append("device_name", device);
        formData.append("utc", utc);
        dispatch(signAction(formData));
    }

    const AppleLogin = async () => {
        const data = await signInWithPopup(auth, providerApple);
        const credential = OAuthProvider.credentialFromResult(data);
        const token = credential.accessToken;
        const user = data.user;
        const email = user.email;
        setType("");
        formData.append("email", email);
        formData.append("type", 4);
        formData.append("lang", lang);
        formData.append("ip_address", ip);
        formData.append("device_name", device);
        formData.append("utc", utc);
        dispatch(signAction(formData));
    }

    const emailRef = () => {
        localStorage.setItem("loginRef", JSON.stringify(1))
        window.location.href = globals.url + "i/login"
    }

    const phoneRef = () => {
        localStorage.setItem("loginRef", JSON.stringify(0))
        window.location.href = globals.url + "i/login"
    }

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
    }, [])

    const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

    return (
        <div>
            <Helmet>
                <title>Vidipass | {_("vidipass_for_all_activity_tickets")}</title>
                <meta name="description" content={_("meta_content_13")}/>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
			</Helmet>
            <Navbar />
            <div className="container mt-5">
                <div className="card p-5 col-md-6 offset-md-3">
                    <div className="row d-flex justify-content-center mb-2">
                        <small className="homeText text-center mb-2 px-2">{_("start_screen_info_1")}</small>
                        <small className="homeText text-center">{_("start_screen_info_2")}</small>
                    </div>
                    <div style={{ color: "#000" }}>
                            <hr style={{ height: 1 }} />
                        </div>
                    <div className="row">
                        <div className="col">
                            <a  onClick={() => gtmTag('register_icon')} className="text-decoration-none" href={globals.url + "i/register"}>
                                <div className="row justify-content-center">
                                    <img width={50} height={'auto'} className="w-50 mb-2" src={images.register_button_icon} alt="register" />
                                    <h1 className="d-flex justify-content-center" style={{ color: "#933CAC", fontSize:16 }} type="submit">{_("record")}</h1>
                                </div>
                            </a>
                        </div>
                        <div className="col">
                            <a onClick={() => gtmTag('login_icon')} className="text-decoration-none" href={globals.url + "i/login"}>
                                <div className="row justify-content-center">
                                    <img width={50} height={'auto'} className="w-50 mb-2" src={images.login_button_icon} alt="login" />
                                    <h2 className="d-flex justify-content-center" style={{ color: "#933CAC", fontSize:16 }} type="submit">{_("login")}</h2>
                                </div>
                            </a>
                        </div>
                        <div style={{ color: "#000" }}>
                            <hr style={{ height: 1 }} />
                        </div>
                    </div>
                    <div className="row justify-content-center mb-2">
                        <h1 className="d-flex justify-content-center" style={{ color: "#933CAC", fontSize:18 }} type="submit">{_("quick_login")}</h1>
                    </div>
                    <div className="row d-flex justify-content-center">
                        {
                            !instagramBrowser &&
                            <div onClick={() =>  gtmTag('continue_google_icon')} className="row mb-2">
                                <div className="border rounded-2 p-1" style={{ backgroundColor: "#933CAC" }} onClick={googleLogin} type="submit">
                                    <div className="m-1 d-flex justify-content-start align-items-center text-white">
                                        <img className="border rounded-2 p-1" src={images.google} width="40" height="auto" style={{marginRight: 30 }} alt="google" />
                                        <span className="text-truncate">{_("continue_with_google")}</span>
                                    </div>
                                </div>
                            </div>
                        }
                        <div onClick={() =>  gtmTag('continue_apple_icon')} className="row mb-2">
                            <div className="border rounded-2 p-1" style={{ backgroundColor: "#933CAC" }} onClick={AppleLogin} type="submit">
                                <div className="m-1 d-flex justify-content-start align-items-center text-white">
                                    <img className="border rounded-2 p-1" src={images.apple} width="40" height="auto" style={{marginRight: 30 }} alt="apple" />
                                    <span className="text-truncate"> {_("continue_with_apple")}</span>
                                </div>
                            </div>
                        </div>
                        
                        <div onClick={() =>  gtmTag('continue_actividi_icon')} className="row mb-2">
                            <div className="border rounded-2 p-1" style={{ backgroundColor: "#933CAC" }} type="submit" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <div className="m-1 d-flex justify-content-start align-items-center text-white ">
                                    <img className="border rounded-2 p-1" src={images.actividi} width="40" height="auto" style={{marginRight: 30 }} alt="actividi" />
                                    <span className="text-truncate">{_("continue_with_actividi")}</span>
                                </div>
                            </div>
                        </div>
                        <div onClick={() =>  gtmTag('continue_email_icon')} className="row mb-2">
                            <div className="border rounded-2 p-1 " style={{ backgroundColor: "#933CAC" }} type="submit" onClick={() => emailRef()}>
                                <div className="m-1 d-flex justify-content-start align-items-center text-white ">
                                    <img className="border rounded-2 p-1" src={images.email_icon} width="40" height="auto" style={{ marginRight: 30 }} alt="email" />
                                    <span className="text-truncate">{_("continue_with_mail")}</span>
                                </div>
                            </div>
                        </div>
                        <div onClick={() =>  gtmTag('continue_phone_icon')} className="row mb-2">
                            <div className="border rounded-2 p-1" style={{ backgroundColor: "#933CAC" }} type="submit" onClick={() => phoneRef()}>
                                <div className="m-1 d-flex justify-content-start align-items-center text-white ">
                                    <img className="border rounded-2 p-1" src={images.phone_icon} width="40" height="auto" style={{marginRight: 30 }} alt="phone" />
                                    <span className="text-truncate">{_("continue_with_phone")}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{ color: "#000" }}>
                            <hr style={{ height: 1 }} />
                        </div>
                        <div className="form-check">
                            <small className="form-check-label mt-2" htmlFor="exampleCheck1">
                                {/* <input  type="checkbox" className="form-check-input" id="exampleCheck1" checked={term} onChange={onChangeTerm} /> */}
                                {_("terms_of_use_01")} <a href={globals.url + "i/legal"} target="_blank" style={{ color: "#933CAC", textDecoration: "none" }}>{_("terms_of_use_02")}</a>{_("terms_of_use_03")}
                            </small>
                        </div>
                    </div>
                    <div className="modal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body row d-flex justify-content-center">
                                    <span className="row d-flex justify-content-center mb-2">{_("actividi_info")}</span>
                                    <button type="button" className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" data-bs-dismiss="modal">{_("close")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="card card2 order-1 me-1 mb-1">
                        {sloganNumber == 1 ? <img src={images.slogan_1} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 2 ? <img src={images.slogan_2} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 3 ? <img src={images.slogan_3} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 4 ? <img src={images.slogan_4} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 5 ? <img src={images.slogan_5} className="w-100 rounded-1" alt="..." /> :
                            <img src={images.slogan_6} className="w-100 rounded-1" alt="..." /> 
                        }
                    </div> */}
            </div>
            <span ref={divRef} />
            <Footer />
        </div>
    )
}

export default Start