import React, { useState, useEffect, useRef } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import { _ } from '../config/languages/i18n';
import * as Popover from '@radix-ui/react-popover';
import globals from "../config/globals/globals";
import axios from "axios";

const initialSeats = (rows, columns) => {
  let seats = [];
  for (let i = 0; i < rows; i++) {
    let row = [];
    for (let j = 0; j < columns; j++) {
      row.push({
        label: '', // Başlangıçta boş
        category: '',
        status: 'empty', // Başlangıçta empty
        color: '',
        priceType: '',
        price: 0
      });
    }
    seats.push(row);
  }
  return seats;
};


const App = () => {
  const [rows, setRows] = useState(5);
  const [columns, setColumns] = useState(5);
  const [categories, setCategories] = useState([]);
  const [seats, setSeats] = useState(initialSeats(rows, columns));
  const [rowLabels, setRowLabels] = useState(Array.from({ length: rows }, (_, i) => String.fromCharCode(65 + i)));
  const [newCategory, setNewCategory] = useState({ name: '', prices: [], color: '#000' });
  const [newPrice, setNewPrice] = useState({ type: '', amount: 0 });
  const categoryRef = useRef([])
  const [hallName,setHallName] = useState('');
  const formData = new FormData();
  const [token,setToken] =useState();
  const HTTP = axios.create({ baseURL: globals.apiUrl });
  const [stagePositions, setStagePositions] = useState([]);


console.log(categories)

const handleSelectStage = (rowIndex, colIndex) => {
  setStagePositions(prevPositions => {
    const isAlreadyStage = prevPositions.some(
      (pos) => pos.rowIndex === rowIndex && pos.colIndex === colIndex
    );
    if (isAlreadyStage) {
      return prevPositions.filter(
        (pos) => !(pos.rowIndex === rowIndex && pos.colIndex === colIndex)
      );
    } else {
      return [...prevPositions, { rowIndex, colIndex }];
    }
  });
};


const handleInputChangeName = (index, e) => {
  const newInputs = [...categories];
  newInputs[index].name = e.target.value;    
  setCategories(newInputs);
};

const handleInputChangeType = (index, e, i) => {
  const newInputs = [...categories];
  newInputs[index].prices[i].type = e.target.value;    
  setCategories(newInputs);
};

const handleInputChangeAmount = (index, e, i) => {
  const newInputs = [...categories];
  newInputs[index].prices[i].amount = e.target.value;    
  setCategories(newInputs);
};

const handleInputChangeColor = (index, e) => {
  const newInputs = [...categories];
  newInputs[index].color = e.target.value;    
  setCategories(newInputs);
};


const handleRemove = (index) => {
  // Silinecek kategoriyi al
  const removedCategory = categories[index];

  // Yeni kategori listesini oluştur
  const newInputs = categories.filter((_, i) => i !== index);
  setCategories(newInputs);

  // Koltukların durumunu güncelle
  const updatedSeats = seats.map(row =>
    row.map(seat => {
      if (seat.category === removedCategory.name) {
        return {
          ...seat,
          status: 'empty', // Kategoriyi kaldırdığınızda koltuk durumunu boş yapın
          category: '', // Kategori adını temizleyin
          color: '', // Rengi temizleyin
          label: '', // Label'ı temizleyin
          priceType: '', // Fiyat tipini temizleyin
          price: 0 // Fiyatı sıfırlayın
        };
      }
      return seat;
    })
  );
  setSeats(updatedSeats);
};



useEffect(() => {
  const newSeats = initialSeats(rows, columns);
  const updatedSeats = newSeats.map((row, rowIndex) => 
    row.map((seat, colIndex) => {
      if (seats[rowIndex] && seats[rowIndex][colIndex]) {
        return { ...seat, ...seats[rowIndex][colIndex] };
      }
      return seat;
    })
  );
  
  setSeats(updatedSeats);
  setRowLabels(Array.from({ length: rows }, (_, i) => String.fromCharCode(65 + i)));
}, [rows, columns]);


  useEffect(() => {
    const updatedSeats = seats.map(row =>
      row.map(seat => {
        const category = categories.find(cat => cat.name === seat.category);
        if (category && seat.color !== category.color) {
          return { ...seat, color: category.color };
        }
        return seat;
      })
    );
    setSeats(updatedSeats);
  }, [categories]);
  

  useEffect(() => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    if (getuser) {
        if (getuser.result) {
            if (getuser.token) {
                setToken(getuser.token);
            }
        }
    }
}, []);


console.log(seats)

  const handleReserveSeats = () => {
    const updatedSeats = seats.map(row => row.map(seat => seat.status === 'selected' ? { ...seat, status: 'reserved' } : seat));
    setSeats(updatedSeats);

    formData.append("title", hallName);
    formData.append("seats_plan", JSON.stringify(seats));

    HTTP.post("/addHallPlan", formData,
    { headers: { Authorization: `bearer ${token}` } }
    ).then((res) => {
          console.log(res)
        })
  };

  const handleAddCategory = () => {
    if (newCategory.name && newCategory.prices.length > 0 && newCategory.color) {
      setCategories([...categories, newCategory]);
      setNewCategory({ name: '', prices: [], color: '#000' });
      setNewPrice({ type: '', amount: 0 });
    }
  };

  const handleAddPrice = () => {
    if (newPrice.type && newPrice.amount > 0) {
      setNewCategory({ ...newCategory, prices: [...newCategory.prices, newPrice] });
      setNewPrice({ type: '', amount: 0 });
    }
  };

  const handleAssignCategory = (rowIndex, colIndex, category) => {
    const updatedSeats = [...seats];
    const seat = updatedSeats[rowIndex][colIndex];
  
    // Önce eski kategoriyi temizle
    if (seat.category && seat.category !== category.name) {
      // Önce eski kategoriye ait değerleri temizle
      seat.status = 'empty';
      seat.color = '';
      seat.label = '';
      seat.category = '';
      seat.priceType = '';
      seat.price = 0;
    }
  
    // Yeni kategoriyi atayın
    if (category.name) {
      seat.status = 'selected';
      seat.color = category.color;
      seat.label = getUpdatedLabel(rowIndex, colIndex); // Koltuk adını sıraya göre belirle
      seat.category = category.name; // Kategori adı atanıyor
      seat.priceType = category.prices.map(price => price.type).join(', '); // Fiyat tipleri atanıyor
      seat.price = category.prices.map(price => price.amount).join(', '); // Fiyatlar atanıyor
    } else {
      seat.status = 'empty';
      seat.color = '';
      seat.label = '';
      seat.category = ''; // Kategori adı temizleniyor
      seat.priceType = ''; // Fiyat tipi temizleniyor
      seat.price = 0; // Fiyat sıfırlanıyor
    }
  
    updatedSeats[rowIndex][colIndex] = seat;
    setSeats(updatedSeats);
  };
  

  const handleLabelChange = (rowIndex, colIndex, newLabel) => {
    const updatedSeats = [...seats];
    updatedSeats[rowIndex][colIndex].label = newLabel;
    setSeats(updatedSeats);
  };

  const handleRowLabelChange = (rowIndex, newLabel) => {
    const updatedLabels = [...rowLabels];
    updatedLabels[rowIndex] = newLabel;
  
    // Satır adlarını sıralı olarak güncelle
    for (let i = rowIndex + 1; i < updatedLabels.length; i++) {
      const previousLabel = updatedLabels[i - 1];
      if (previousLabel) {
        const nextLabel = String.fromCharCode(previousLabel.charCodeAt(0) + 1);
        updatedLabels[i] = nextLabel;
      } else {
        updatedLabels[i] = '';
      }
    }
  
    setRowLabels(updatedLabels);
  
    // Koltuk etiketlerini güncelle
    const updatedSeats = seats.map((row, index) =>
      row.map((seat, colIndex) => {
        if (index === rowIndex) {
          if (seat.status === 'selected') {
            // Seçili koltuklar için yeni ad
            return {
              ...seat,
              label: newLabel ? `${newLabel}${colIndex + 1}` : '',
            };
          } else if (seat.status === 'stage' || seat.status === 'empty') {
            // Stage veya boş koltuklar için etiketi temizle
            return {
              ...seat,
              label: '',
            };
          }
        } else if (index > rowIndex) {
          // Alt satırlardaki koltuklar için yeni ad
          if (seat.status === 'selected') {
            return {
              ...seat,
              label: updatedLabels[index] ? `${updatedLabels[index]}${colIndex + 1}` : '',
            };
          }
        }
        return seat;
      })
    );
  
    setSeats(updatedSeats);
  };
  
  
  

  const getUpdatedLabel = (rowIndex, colIndex) => {
    const rowSeats = seats[rowIndex];
    const selectedSeats = rowSeats
      .slice(0, colIndex + 1)
      .filter(seat => seat.status === 'selected');
  
    return rowLabels[rowIndex] ? `${rowLabels[rowIndex]}${selectedSeats.length}` : '';
  };
  
  
  useEffect(() => {
    const updatedSeats = seats.map((row, rowIndex) =>
      row.map((seat, colIndex) => {
        return {
          ...seat,
          label: seat.isStage ? '' : getUpdatedLabel(rowIndex, colIndex),
        };
      })
    );
    setSeats(updatedSeats);
  }, [rowLabels]); // rowLabels değiştiğinde çalışacak
  
  
  
  
  
  useEffect(() => {
    const updatedSeats = seats.map((row, rowIndex) =>
      row.map((seat, colIndex) => {
        if (seat.status === 'selected') {
          return {
            ...seat,
            label: getUpdatedLabel(rowIndex, colIndex)
          };
        }
        return seat;
      })
    );
    setSeats(updatedSeats);
  }, [seats.map(row => row.map(seat => seat.status)).flat().join(',')]); // Sadece 'status' alanını izler
  

  const handleStageSelection = (rowIndex, colIndex) => {
    const updatedSeats = [...seats];
    const seat = updatedSeats[rowIndex][colIndex];
  
    // Sahne olarak ayarlanırsa koltuk adını temizle
    seat.label = '';
    seat.status = 'stage';
    seat.color = '#FFD700'; // Sahne rengi
  
    updatedSeats[rowIndex][colIndex] = seat;
    setSeats(updatedSeats);
  };
  
  

  const handleStageRemoval = (rowIndex, colIndex) => {
    const updatedSeats = [...seats];
    const seat = updatedSeats[rowIndex][colIndex];
  
    // Sahne durumu iptal edilirse koltuk adını temizle
    seat.label = '';
    seat.status = 'empty';
    seat.color = '';
  
    updatedSeats[rowIndex][colIndex] = seat;
    setSeats(updatedSeats);
  };
  
  return (
    <div>
      <Navbar />
      <div className="container mt-5">
        <h3 className="homeText">{_('seating_arrangement')}</h3>
      <div className="d-flex flex-wrap mb-4 col-sm-12 border border-secondary-subtle p-2 rounded-2">
        <label className="form-control-label text-muted">{_("hall_name")}</label>
        <input label="Rows" type="text" value={hallName} onChange={(e) => setHallName(e.target.value)} />
      </div>
      <div className="d-flex flex-wrap mb-4 col-sm-12 gap-5 border border-secondary-subtle p-2 rounded-2">
        <div className="col">
          <label className="form-control-label text-muted">{_("row_number")}</label>
          <input label="Rows" type="number" value={rows} onChange={(e) => setRows(parseInt(e.target.value) || 0)} />
        </div>
        <div className="col">
          <label className="form-control-label text-muted">{_("col_number")}</label>
          <input label="Columns" type="number" value={columns} onChange={(e) => setColumns(parseInt(e.target.value) || 0)} />
        </div>
      </div>

      <div className="d-flex flex-wrap mb-4 col-sm-12 gap-5 border border-secondary-subtle p-2 rounded-2">
        <div className="col">
          <label className="form-control-label text-muted">{_("category_name")}</label>
          <input label="Category Name" value={newCategory.name} onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })} />
        </div>
        <div className="col">
          <label className="form-control-label text-muted">{_("category_color")}</label>
          <input label="Color" type="color" value={newCategory.color} onChange={(e) => setNewCategory({ ...newCategory, color: e.target.value })} />
        </div>
      </div>

      <div className="d-flex flex-wrap col-sm-12 gap-5 border border-secondary-subtle p-2 rounded-2">
        <div className="col">
        <label className="form-control-label text-muted">{_("ticket_type")}</label>
          <input label="Ticket Type" value={newPrice.type} onChange={(e) => setNewPrice({ ...newPrice, type: e.target.value })} />
          <label className="form-control-label text-muted">{_("fee")}</label>
          <input label="Price" type="number" value={newPrice.amount} onChange={(e) => setNewPrice({ ...newPrice, amount: parseInt(e.target.value) || 0 })} />
        </div>
        <div className="d-flex align-self-center">
          <button className="text-decoration-none btn btn-primary cardButton rounded-2" variant="contained" onClick={handleAddPrice}>{_("add_ticket_type")}</button>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3 mb-3"> 
        <button className="text-decoration-none btn btn-primary cardButton rounded-2" variant="contained" onClick={handleAddCategory}>{_("add_category")}</button>
      </div>

      {
        categories.length>0 &&
        <>
          <h4 className="homeText">{_('category')}</h4>
          <div className="d-flex flex-wrap gap-3 border border-secondary-subtle p-2 rounded-2 justify-content-space-between mb-3">
            {
              categories.map((val,index)=>{
                return(
                  <>
                  <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 border border-secondary-subtle p-2 rounded-2 position-relative">
                    <div onClick={() => categoryRef.current[index].focus()} style={{backgroundColor:'#933CAC'}} className="rounded border border-secondary-subtle position-absolute edit-icon">
                      <span style={{color:'#FFFFFF'}} className="bi bi-pencil-square mx-1"/>
                    </div>
                    <div onClick={(e) => handleRemove(index)} style={{backgroundColor:'#933CAC'}} className="rounded border border-secondary-subtle position-absolute delete-icon">
                      <span style={{color:'#FFFFFF'}} className="bi bi-x-square m-1"/>
                    </div>
                    <label className="form-control-label text-muted">{_("category_name")}</label>
                    <input
                      ref={(el) => categoryRef.current[index] = el}
                      type="text"
                      value={val.name}
                      onChange={(e) =>handleInputChangeName(index,e)}
                    />
                  <label className="form-control-label text-muted">{_("category_color")}</label>
                  <input label="Color" type="color" value={val.color} onChange={(e) =>handleInputChangeColor(index,e)} />
                  <div className="row">
                    <div className="col">
                    <label className="form-control-label text-muted">{_("ticket_type")}</label>
                    </div>
                    <div className="col">
                    <label className="form-control-label text-muted">{_("fee")}</label>
                    </div>
                  </div>
                      {
                      val?.prices.map((res,i)=>{
                        return(
                          <div className="row">
                            <div className="col">
                              <input
                                type="text"
                                value={res.type}
                                onChange={(e) => handleInputChangeType(index,e,i)}
                              />
                            </div>
                            <div className="col">
                              <input
                                type="text"
                                value={res.amount}
                                onChange={(e) => handleInputChangeAmount(index,e,i)}
                              />
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  </>
                )
              }) 
            }
          </div>
        </>
      }
<div id="seat-map" className="seat-map border border-secondary-subtle p-2 rounded-2">
  {seats.map((row, rowIndex) => (
    <div key={rowIndex} className="seat-row">
      <div className="seat-row-label">
        <input
          type="text"
          value={rowLabels[rowIndex]}
          onChange={(e) => handleRowLabelChange(rowIndex, e.target.value)}
          className="seat-label"
        />
      </div>
      {row.map((seat, colIndex) => {
  const isStage = seat.status === 'stage'; // Koltuğun sahne olup olmadığını kontrol et
  const showLabel = seat.status !== 'empty'; // Sadece kategori atanmış koltuklar için label göster
  const updatedLabel = getUpdatedLabel(rowIndex, colIndex);

  return (
    <Popover.Root key={colIndex}>
      <Popover.Trigger asChild>
        <div 
          className={`seat ${seat.status} ${isStage ? 'stage-seat' : ''}`} 
          style={{ backgroundColor: isStage ? '#FFD700' : seat.color }} 
          onClick={() => ''}
        >
          {/* Label'ı sadece boş değilse ve koltuğa kategori atanmışsa göster */}
          {showLabel && (
          <input
            type="text"
            value={seat.label}
            onChange={(e) => handleLabelChange(rowIndex, colIndex, e.target.value)}
            className="seat-label"
            disabled={isStage}
          />
)}

        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className="PopoverContent border border-primary-subtle" sideOffset={5}>
          <div className="select-container d-flex flex-column gap-2">
            {categories.map((category, index) => (
              <button
                key={index}
                className="btn btn-sm btn-outline-primary"
                onClick={() => handleAssignCategory(rowIndex, colIndex, category)}
              >
                {category.name}
              </button>
            ))}
            <button 
              className="btn btn-sm btn-outline-warning"
              onClick={() => handleAssignCategory(rowIndex, colIndex, { name: '', color: '', prices: [], priceType: '', price: 0 })}
            >
              Seçimi İptal Et
            </button>
            {isStage ? (
              <button 
                className="btn btn-sm btn-outline-danger mt-2"
                onClick={() => handleStageRemoval(rowIndex, colIndex)}
              >
                Sahne Olarak İptal Et
              </button>
            ) : (
              <button 
                className="btn btn-sm btn-outline-secondary mt-2"
                onClick={() => handleStageSelection(rowIndex, colIndex)}
              >
                Sahne Olarak Ayarla
              </button>
            )}
          </div>
          <Popover.Close className="bi bi-x-circle-fill PopoverClose"/>
             

          

         
          <Popover.Arrow className="PopoverArrow" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
})}


    </div>
  ))}
</div>


      <div className="d-flex justify-content-center mt-3 mb-3">
        <button variant="contained" className="text-decoration-none btn btn-primary cardButton rounded-2 mt-3" onClick={handleReserveSeats}>{_('save')}</button>
      </div>
    </div>
      <Footer />
    </div>
  );
};

export default App;