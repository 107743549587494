import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import "react-phone-number-input/style.css";
import { useState, useEffect } from "react";
import globals from "../config/globals/globals";
import { _ } from "../config/languages/i18n";
import moment from "moment";
import axios from "axios";
import Loader from "../components/Loader";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Modal from 'react-bootstrap/Modal';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import ShowMoreText from "react-show-more-text";
import Linkify from 'linkify-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import { QRCode } from 'react-qrcode-logo';
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";

const ActivityReport = () => {
    const [loading, setLoading] = useState(false);
    const [activityName, setActivityName] = useState("");
    const [activityCategoryName, setActivityCategoryName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endDate, setEndDate] = useState("");
    const [endTime, setEndTime] = useState("");
    const [description, setDescription] = useState("");
    const [number, setNumber] = useState();
    const [category, setCategory] = useState("null");
    const [seatCategory, setSeatCategory] = useState("null");
    const [activityId, setActivityId] = useState("");
    const [activityPhoto, setActivityPhoto] = useState("");
    const [token, setToken] = useState("");
    const [hall, setHall] = useState("");
    const [seat, setSeat] = useState("");
    const [seatName, setSeatName] = useState("");
    const [groupId, setGroupId] = useState("");
    const [seatId, setSeatId] = useState("");
    const [price, setPrice] = useState("");
    const [ticket, setTicket] = useState("");
    const [priceCode, setPriceCode] = useState("");
    const [utc, setUTC] = useState("")
    const [counter, setCounter] = useState("");
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const [showSaloonModal, setShowSaloonModal] = useState(false);
    const [deleteCartModal, setDeleteCartModal] = useState(false);
    const [deleteTicketModal, setDeleteTicketModal] = useState(false);
    const [deleteTicketId, setDeleteTicketId] = useState(false)
    const [imgCreated, setImgCreated] = useState("")
    const [planURL, setPlanURL] = useState("")
    const [activity, setActivity] = useState("")
    const [payTicketNumber, setPayTicketNumber] = useState((activity?.hall?.groups[0]?.total_tickets) - (activity?.hall?.groups[0]?.solded_tickets))
    const [place, setPlace] = useState("")
    const [extra, setExtra] = useState("")
    const [soldTickets, setSoldTickets] = useState([])
    const [unsoldTickets, setUnsoldTickets] = useState([])
    const [soldModal, setSoldModal] = useState(false);
    const [unsoldModal, setUnsoldModal] = useState(false);
    const [urlModal, setUrlModal] = useState(false)
    const [copy, setCopy] = useState(false)
    const [startDateDay, setStartDateDay] = useState("")
    const [endDateDay, setEndDateDay] = useState("")
    const [showQrModal, setShowQrModal] = useState(false)
    const [qrURL, setQrUrl] = useState("")
    const [cityName, setCityName] = useState("")

    var totalTicket = 0;
    var soldedTicket = 0;

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        if (getuser.result) {
            setToken(getuser.token);
        }
        setLoading(true);
        setNumber(window.location.href.split("/")[5]);
        setQrUrl(globals.url + 'a/' + number)
        if (number) {
            HTTP.get(`/activities/get/${number}`, { headers: { Authorization: `bearer ${getuser.token}` }, })
                .then((res) => {
                    if (res.data.result) {
                        setCityName(res.data.activity.city.name)
                        setActivityName(res.data.activity.title);
                        setStartDate(moment(res.data.activity.start_time).format("DD.MM.YYYY"));
                        setStartTime(moment(res.data.activity.start_time).format("HH:mm"));
                        setEndDate(moment(res.data.activity.end_time).format("DD.MM.YYYY"));
                        setEndTime(moment(res.data.activity.end_time).format("HH:mm"));
                        setDescription(res.data.activity.description);
                        setActivityCategoryName(res.data.activity.category.name);
                        setActivityId(res.data.activity.id);
                        setActivityPhoto(res.data.activity.photo_name);
                        setPriceCode(res.data.activity.currency.tcmb_code)
                        setToken();
                        setHall(res.data.activity.hall);
                        setUTC(res.data.activity.utc);
                        setImgCreated(res.data.activity.created_at)
                        setPlanURL(res.data.activity.hall.plan_file_url)
                        setLoading(false);
                        setActivity(res.data.activity)
                        setPayTicketNumber((res.data.activity?.hall?.groups[0]?.total_tickets) - (res.data.activity?.hall?.groups[0]?.solded_tickets))
                        setPlace(res.data.activity.place.title)
                        setExtra(res.data.activity.extra)
                        setStartDateDay(moment(res.data.activity.start_time).format('dddd').toLocaleLowerCase())
                        setEndDateDay(moment(res.data.activity.end_time).format('dddd').toLocaleLowerCase())

                        HTTP.get(`getSeatsAll/${res.data.activity.id}`, { headers: { Authorization: `bearer ${getuser.token}` }, })
                            .then((res) => {
                                if (res.data.result) {
                                    setSoldTickets(res.data.soldTickets)
                                    setUnsoldTickets(res.data.unsoldTickets)
                                } else {
                                    setLoading(false);
                                }
                            })
                            .catch();
                    } else {
                        setLoading(false);
                        window.location.href = globals.url
                    }
                })
                .catch(setLoading(true));
        }
    }, [number]);

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        if (getuser.result) {
            setToken(getuser.token);
        }
        setLoading(true);
        HTTP.get("/getCart", { headers: { Authorization: `bearer ${getuser.token}` } })
            .then((res) => {
                if (res.data.cart) {
                    setTicket(res.data.cart.tickets)
                    setCounter(res.data.cart.cartTime)
                } else {
                    setLoading(false);
                }
            })
            .catch(setLoading(true));
    }, []);

    useEffect(() => {
        ticket ? setTimeout(() => setCounter(counter - 1), 1000) : setCounter("")
    }, [counter]);

    const formattedUtc = (hour) => {
        if (hour == 0) {
            var result = 'UTC';
            return result;
        } else {
            if (hour < 0) {
                var signal = 'UTC - ';
                var hour = hour * -1;
            } else {
                var signal = 'UTC + ';
            }

            if (hour < 10) {
                var formatHour = '0' + parseInt(hour);
            } else {
                var formatHour = parseInt(hour);
            }

            if (hour % 1 > 0) {
                var formatMinute = (hour % 1 * 60);
            } else {
                var formatMinute = '00';
            }

            result = signal + formatHour + ':' + formatMinute;
            return result;
        }
    }

    const linkProps = {
        onClick: (event) => {
            event.preventDefault()
            //  window.open(extra)
            setCopy(false)
            setUrlModal(true)
        }
    };

    const urlCopy = () => {
        setCopy(true)
        toast(_('copied'), {
            position: "top-right",
            autoClose: 3000,
            type: 'success',
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton: false
        })
        setUrlModal(false)
    }

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Activity Report Page'
            },
            event: 'activity_report_page'
        })
    }, [])

    return (
        <div>
            <Helmet>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
			</Helmet>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Navbar />
                    <div className="container mb-5 px-4 mt-5">
                        <Modal show={soldModal} onHide={() => setSoldModal(false)}>
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("sold_seats")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
                                {soldTickets.map(function (res) {
                                    return (
                                        <div className="row">
                                            <p className="homeText">{res.name}</p>
                                            <div className="row">
                                                <div className="col-2 d-flex justify-content-start">
                                                    <p style={{ fontSize: 13 }} className="homeText">{_("armchair")}</p>
                                                </div>
                                                <div className="col-5 d-flex justify-content-start">
                                                    <p style={{ fontSize: 13 }} className="homeText">{_("ticket_no")}</p>
                                                </div>
                                                <div className="col-5 d-flex justify-content-center">
                                                    <p style={{ fontSize: 13 }} className="homeText">{_("name_on_ticket")}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            {res.seats.map(function (ind) {
                                                if (ind.is_solded) {
                                                    return (
                                                        <>
                                                            <div className="row">
                                                                <div className="col-2 d-flex justify-content-start">
                                                                    <span style={{ fontSize: 12 }}>{ind.name}</span>
                                                                </div>
                                                                <div className="col-6 d-flex justify-content-start">
                                                                    <p style={{ fontSize: 12 }}>{ind.ticket_number}</p>
                                                                </div>
                                                                <div className="col-4 d-flex justify-content-start">
                                                                    <p style={{ fontSize: 12 }}>{ind.name_on_ticket}</p>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                        </>
                                                    )
                                                }
                                            })
                                            }
                                        </div>
                                    )
                                })
                                }
                            </Modal.Body>
                        </Modal>

                        <Modal show={unsoldModal} onHide={() => setUnsoldModal(false)}>
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("rest_seats")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
                                {unsoldTickets.map(function (val) {
                                    return val.map(function (res) {
                                        return (
                                            <div className="row">
                                                <p className="homeText">{res.name}</p>
                                                <hr />
                                                {res.seats.map(function (ind) {
                                                    if (!ind.is_solded) {
                                                        return (
                                                            <div className="col">
                                                                <div className="row d-flex text-start">
                                                                    <div className="col">
                                                                        <p >{ind.name}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                }
                                            </div>
                                        )
                                    })
                                })
                                }
                            </Modal.Body>
                        </Modal>

                        <Modal show={urlModal} onHide={() => setUrlModal(false)}>
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("online_adress")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
                                <div className="d-flex justify-content-center align-items-center px-2">
                                    <CopyToClipboard text={extra} onCopy={() => setCopy(true) + urlCopy()}>
                                        <div className="border rounded-2 p-2 d-flex me-2 d-flex justify-content-center"  >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                fill="#933CAC"
                                                className="bi bi-copy"
                                                viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z" />
                                            </svg>
                                        </div>
                                    </CopyToClipboard>
                                    <a onClick={() => window.open(extra) + setUrlModal(false)} className="homeText d-flex text-decoration-none border rounded-2 p-2">{extra}</a>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <div className="row flex-column flex-lg-row ">
                            <div className="col-12 card-back p-1 rounded-2">
                                <div className="col-12 justify-content-center card">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-5 justify-content-center">
                                            {activityPhoto &&
                                                <img
                                                    src={globals.imgUrl + activityId + "/thumb/" + activityPhoto + ".png" + '?' + new Date(imgCreated).getTime()}
                                                    className="w-100 rounded-2"
                                                    alt="..." />
                                            }
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div className="row">
                                                <h3 className="text-center" style={{ color: "#2E81D2" }}>{activityName}</h3>
                                            </div>
                                            <h5 className="text-center homeText">{_('category')}</h5>
                                            <p className="text-center text-muted">
                                                {_(activityCategoryName)}
                                            </p>
                                            <div className="row text-center">
                                                <div className=" col">
                                                    <h5 className="homeText text-center text-truncate">{_('start_time')}</h5>
                                                    <div className="row">
                                                        <span className="text-muted">
                                                            <i style={{ color: "#933CAC" }} className="bi bi-calendar-check me-2"></i>
                                                            {startDate}
                                                        </span>
                                                        <span className="text-muted">
                                                            {_(startDateDay)}
                                                        </span>
                                                        <span className="text-muted">
                                                            <i style={{ color: "#933CAC" }} className="bi bi-clock me-2"></i>
                                                            {startTime} <span style={{ fontSize: 11 }}></span>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <h5 className="homeText text-center text-truncate">{_('end_time')}</h5>
                                                    <div className="row">
                                                        <span className="text-muted">
                                                            <i style={{ color: "#933CAC" }} className="bi bi-calendar-check me-2"></i>
                                                            {endDate}
                                                        </span>
                                                        <span className="text-muted">
                                                            {_(endDateDay)}
                                                        </span>
                                                        <span className="text-muted">
                                                            <i style={{ color: "#933CAC" }} className="bi bi-clock me-2"></i>
                                                            {endTime} <span style={{ fontSize: 11 }}>({formattedUtc(utc)})</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row text-center">
                                                <h5 className="homeText text-center text-truncate">{_('place')}</h5>
                                                <span className="text-muted">
                                                    {cityName}
                                                </span>
                                                <p className="text-muted">
                                                    <i style={{ color: "#933CAC" }} className="bi bi-geo-alt me-2"></i>
                                                    {place}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                    description &&
                                    <h5 className="text-center homeText">
                                    {_("about_the_activity")}
                                    </h5>
                                    }
                                    <div className="text-start text-muted px-2">
                                        <ShowMoreText
                                            lines={5}
                                            more={_("continue")}
                                            less=""
                                        >
                                            <p style={{ whiteSpace: "pre-line" }}>{description}</p>
                                        </ShowMoreText>
                                    </div>
                                    <div className="col-sm-12 col-lg-12">
                                        <div className="card-back m-4 py-1 rounded">
                                            <div className="row card mx-1 d-flex">
                                                <h3 style={{fontSize:20}} className="text-center homeText">
                                                    {_("information")}
                                                </h3>
                                                <p className="text-center text-muted">
                                                    <Linkify options={{ className: "homeText text-decoration-none", attributes: linkProps }}>
                                                        {_("actividi_info_1").replace("%s", extra)}
                                                    </Linkify>
                                                </p>
                                                <p className="text-center text-muted">{_("actividi_info_2")}</p>
                                                <p className="text-center text-muted">{_("actividi_info_3")}</p>
                                                <h3 style={{fontSize:20}} className="text-center homeText">
                                                    {_("warning")}
                                                </h3>
                                                <p className="text-center text-muted">
                                                    {_("warning_01")}
                                                </p>
                                                <p className="text-center text-muted">
                                                    {_("warning_02")}
                                                </p>
                                                <p className="text-center text-muted">
                                                    {_("warning_03")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={() => setShowQrModal(true)} className="d-flex justify-content-center">
                                        <div style={{ padding: 35 }} className="border rounded">
                                            <div style={{ transform: 'rotate(45deg)' }}>
                                                <QRCode value={qrURL}
                                                    qrStyle="dots"
                                                    eyeColor={['#D31095', '#2E81D2', '#933CAC']}
                                                    logoImage={images.vidipass_qr_icon}
                                                    quietZone={0}
                                                    size={120}
                                                    logoWidth={25}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-center text-muted">vidipass.com/a/{number}</p>
                                </div>
                            </div>

                            <div className="col-sm-12 card p-3 mt-2">
                                <div className="row">
                                    <h5 className="text-start homeText">{_("activity_report")}</h5>
                                </div>
                                <div className="row mt-2 mb-2">
                                    <span style={{ fontSize: 18 }} className="text-start homeText">{_("activity_code")}
                                    </span>
                                    <span className="text-start text-muted">{number}</span>
                                </div>
                                <div className="row mt-2">
                                    <span style={{ fontSize: 18 }} className="text-start homeText">{_("created_time")}</span>
                                </div>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>{_("date")}</Th>
                                            <Th>{_("hour")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td>{moment(activity.created_at).format("DD.MM.YYYY")}</Td>
                                            <Td>{moment(activity.created_at).format("HH:mm")} <span style={{ fontSize: 11 }}>({formattedUtc(utc)})</span></Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                                <div className="row mt-3">
                                    <span style={{ fontSize: 18 }} className="text-start homeText">{_("details")}</span>
                                </div>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>{_("category")}</Th>
                                            <Th>{_("total_ticket")}</Th>
                                            <Th>{_("solded_ticket")}</Th>
                                            <Th>{_("rest_ticket")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {activity?.hall?.groups.map(val => {
                                            totalTicket += val.total_tickets;
                                            soldedTicket += val.solded_tickets
                                            return (<Tr>
                                                <Td>{val.name}</Td>
                                                <Td>{val.total_tickets}</Td>
                                                <Td>{val.solded_tickets}</Td>
                                                <Td>{val.total_tickets - val.solded_tickets}</Td>
                                            </Tr>
                                            )
                                        })
                                        }
                                    </Tbody>
                                </Table>
                                <div className="row mt-3">
                                    <span style={{ fontSize: 18 }} className="text-start homeText">{_("total_ticket")}</span>
                                </div>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>{_("total_ticket")}</Th>
                                            <Th>{_("solded_ticket")}</Th>
                                            <Th>{_("rest_ticket")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td>{totalTicket}</Td>
                                            <Td>{soldedTicket}</Td>
                                            <Td>{totalTicket - soldedTicket}</Td>
                                        </Tr>
                                    </Tbody>
                                </Table>

                                <div className="col-sm-12 card p-3 mt-2">
                                    <div className="text-center mb-3 mt-3 row" onClick={() => ""}>
                                        <a onClick={() => setSoldModal(true)} className="text-decoration-none btn btn-primary saloonButton rounded-2 col me-1">
                                            {_("sold_seats")}
                                        </a>
                                        <a onClick={() => setUnsoldModal(true)} className="text-decoration-none btn btn-primary saloonButton rounded-2 col ms-1">
                                            {_("rest_seats")}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={showQrModal} onHide={() => setShowQrModal(false)}>
                        <Modal.Header closeButton style={{ background: "#fff" }}>
                            <span className="text-center text-muted">vidipass.com/a/{number}</span>
                        </Modal.Header>
                        <Modal.Body >
                            <div style={{ transform: 'rotate(45deg)' }} className="col-12 d-flex justify-content-center align-items-center mt-5 mb-5">
                                <QRCode value={qrURL}
                                    qrStyle="dots"
                                    eyeColor={['#D31095', '#2E81D2', '#933CAC']}
                                    logoImage={images.vidipass_qr_icon}
                                    quietZone={0}
                                    size={200}
                                    logoWidth={40}
                                />
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Footer />
                </>
            )}
        </div>
    );
};

export default ActivityReport;