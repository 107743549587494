import ".././index.css";
import { useEffect, useState } from "react";
import { _ } from "../config/languages/i18n";
import globals from '../config/globals/globals';
import images from "../images/images";
import axios from "axios";
import { Modal } from "react-bootstrap";
import TagManager from 'react-gtm-module'

const Navbar = () => {
    const [user, setUser] = useState("");
    const [token, setToken] = useState("");
    const [ticket, setTicket] = useState("");
    const [userType, setUserType] = useState("")
    const [basketModal, setBasketModal] = useState(false)

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        if (getuser) {
            if (getuser.result) {
                if (getuser.token) {
                    setToken(getuser.token);
                    setUser(getuser.user)
                    setUserType(getuser.user.type)
                    localStorage.removeItem("reference");
                }
            }
            HTTP.get("/getCart", {
                headers: { Authorization: `bearer ${getuser.token}` },
            })
                .then((res) => {
                    if (res.data.cart) {
                        setTicket(res.data.cart.tickets)
                    }
                })
                .catch();
        }
    }, []);

    const HTTP = axios.create({ baseURL: globals.apiUrl });

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

    return (

        <nav  style={{backgroundColor:'#E0E0E0'}}  className="navbar navbar-expand-lg container fixed-top py-0">
            <div className="container-fluid">
                <div onClick={()=>gtmTag('vidipass_logo')}>
                    <a className="navbar-brand" href={globals.url}>
                        <img src={images.vidipass_logo} className="figure-img img-fluid rounded " alt="vidipass logo" width="100" height="auto" />
                    </a>
                </div>

                <a onClick={()=>gtmTag('ataturk_image')} className="navbar-brand" href="https://youtu.be/9M1cTfkx3q8?si=wp1D16PZi1xg81qc">
                    <img src={images.ataturk} className="figure-img img-fluid rounded " alt="ataturk" width="50" height="auto" />
                </a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <div className="navbar-nav ms-auto text-end">

                        <a onClick={()=>gtmTag('home_icon')} className="nav-link navLink" aria-current="page" href={globals.url} >{_("home")}<span style={{ backgroundColor: '#933CAC', color: '#FFFFFF' }} className="bi bi-house rounded ms-1 p-1"></span></a>
                        {user.username ?
                            <>
                                {
                                    ticket.length > 0 ?
                                        <a onClick={()=>gtmTag('cart_icon')} className="nav-link navLink" aria-current="page" href={globals.url + "i/payment"}>
                                            <div className="wrapper">
                                                {_('cart')}
                                                <span style={{ backgroundColor: '#933CAC', color: '#FFFFFF' }} className="bi bi-basket navLink wrapper ms-1 rounded p-1" >
                                                    {ticket.length > 0 &&

                                                        <span className="badge rounded-pill badge-notification wrapper-cart" style={{ color: "white", backgroundColor: "#D31095", fontSize: 10 }}>{ticket.length}</span>
                                                    }

                                                </span>
                                                

                                            </div>

                                        </a>
                                        :
                                        <div className="nav-link navLink" aria-current="page" onClick={() => setBasketModal(true) + gtmTag('cart_icon')}>
                                            <div className="wrapper" >
                                                {_('cart')}
                                                <span style={{ backgroundColor: '#933CAC', color: '#FFFFFF' }} className="bi bi-basket navLink wrapper ms-1 rounded p-1" >
                                                    {ticket.length > 0 &&

                                                        <span className="badge rounded-pill badge-notification wrapper-cart" style={{ color: "white", backgroundColor: "#D31095", fontSize: 10 }}>{ticket.length}</span>
                                                    }

                                                </span>
                                                

                                            </div>

                                        </div>

                                }


                                <a onClick={()=>gtmTag('profile_icon')} className="nav-link navLink" aria-current="page" href={globals.url + "i/profile"}>{user.username}<span style={{ background: userType == 3 ? '#000000' : userType == 2 ? "linear-gradient(90deg, #2E81D2, #933CAC, #EC008C)":userType == 4 ? '#EC008C' : '#933CAC', color: '#FFFFFF' }} className="bi bi-person rounded ms-1 p-1"></span></a>
                                <a onClick={(e) => localStorage.setItem("feeRef", JSON.stringify(1)) + gtmTag('vidipassbank_icon')} className="nav-link navLink" aria-current="page" href={globals.url + "i/tickets"}><img width={75} height={'auto'} src={images.vidipassbank_colourful_navbar} alt="vidipass bank" /><span style={{ backgroundColor: '#933CAC', color: '#FFFFFF' }} class="bi bi-wallet2 rounded ms-1 p-1"></span></a>
                                <a onClick={(e) => localStorage.setItem("feeRef", JSON.stringify(0)) + gtmTag('tickets_icon')} className="nav-link navLink" aria-current="page" href={globals.url + "i/tickets"}>{_("my_tickets")}<span style={{ backgroundColor: '#933CAC', color: '#FFFFFF' }} className="bi bi-ticket-perforated rounded ms-1 p-1"></span></a>
                                {
                                    userType == 3 &&
                                    <>
                                        <a  onClick={()=>gtmTag('contact_icon')} href={globals.url + "i/contactMessages"} className="nav-link navLink" aria-current="page">{_("contact")}<span style={{ backgroundColor: '#933CAC', color: '#FFFFFF' }} className="bi bi-chat-right-dots rounded ms-1 p-1"></span></a>
                                        <a  onClick={()=>gtmTag('panel_icon')} href={globals.url + "i/panel"} className="nav-link navLink" aria-current="page">{_("panel")}<span style={{ backgroundColor: '#933CAC', color: '#FFFFFF' }} className="bi bi-key rounded ms-1 p-1"></span></a>
                                    </>
                                }
                                {/* <a onClick={(e) => setSocialModal(true)} className="nav-link navLink" aria-current="page"><span style={{ backgroundColor: '#933CAC', color: '#FFFFFF' }} className="bi bi-link rounded p-1"></span> {_("social_media")}</a> */}
                                <a onClick={(e) => {
                                    localStorage.removeItem("auth");
                                    localStorage.removeItem("reference");
                                    localStorage.removeItem("loginRef");
                                    localStorage.removeItem("feeRef");
                                    setUser(null)
                                    gtmTag('logout_icon')
                                } } className="nav-link navLink" aria-current="page" href={globals.url}>{_("logout")}<span style={{ backgroundColor: '#933CAC', color: '#FFFFFF' }} className="bi bi-box-arrow-in-right rounded ms-1 p-1"></span></a>
                            </>
                            :
                            <>
                                {/* <a onClick={(e) => setSocialModal(true)} className="nav-link navLink" aria-current="page"><span style={{ backgroundColor: '#933CAC', color: '#FFFFFF' }} className="bi bi-link rounded p-1"></span> {_("social_media")}</a> */}
                                <a onClick={()=>gtmTag('login_register_icon')} className="nav-link navLink" aria-current="page" href={globals.url + "i/start"}>{_("login_register")}<span style={{ backgroundColor: '#933CAC', color: '#FFFFFF' }} className="bi bi-box-arrow-in-left rounded ms-1 p-1"></span></a>
                            </>
                        }
                    </div>
                </div>
            </div>
            <Modal show={basketModal} onHide={() => setBasketModal(false)}>
                <Modal.Header closeButton style={{ background: "#fff" }}>
                    <Modal.Title
                        style={{ fontSize: 16 }}
                    ><span className="homeText">{_("basket_info")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="d-flex homeText justify-content-center align-items-center py-2">
                        {_("empty_basket")}
                    </div>

                </Modal.Body>
            </Modal>
            {/* <Modal show={socialModal} onHide={() => setSocialModal(false)}>
                <Modal.Header closeButton style={{ background: "#fff" }}>
                    <Modal.Title
                        style={{ fontSize: 16 }}><span className="homeText">{_("social_media")}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >

                    <div onClick={() => window.open('https://actividi.com/vidipass', '_blank') + setSocialModal(false)} className="d-flex homeText justify-content-start align-items-center p-2 border rounded mb-1">
                        <img src={images.actividi_logo} alt="..." width="40px;" />
                        <span className="ms-3 d-inline-block text-truncate">https://actividi.com/vidipass</span>
                    </div>
                    <div onClick={() => window.open('https://instagram.com/vidipass', '_blank') + setSocialModal(false)} className="d-flex homeText justify-content-start align-items-center p-2 border rounded mb-1">
                        <img src={images.instagram_logo} alt="..." width="40px;" />
                        <span className="ms-3 d-inline-block text-truncate">https://instagram.com/vidipass</span>
                    </div>
                    <div onClick={() => window.open('https://whatsapp.com/channel/0029VaLN2f77oQhhnW8uBH40', '_blank') + setSocialModal(false)} className="d-flex homeText justify-content-start align-items-center p-2 border rounded">
                        <img src={images.whatsapp_logo} alt="..." width="40px;" />
                        <span className="ms-3 d-inline-block text-truncate">https://whatsapp.com/channel/0029VaLN2f77oQhhnW8uBH40</span>
                    </div>

                    <div className="mt-4 text-center row ">
                        <div className="col-4 justify-content-center d-flex">
                            <a href="https://apps.apple.com/tr/app/vidipass/id6476486667" target="_blank">
                                <img src={images.app_store} className="" style={{ height: "2rem", width: "auto" }} alt="..." />
                            </a>
                        </div>
                        <div  className="col-4 justify-content-center d-flex">
                            <a href="https://play.google.com/store/apps/details?id=com.vidipass" cl target="_blank">
                                <img src={images.google_play} className="" style={{ height: "2rem", width: "auto" }} alt="..." />
                            </a>
                        </div>
                        <a className="col-4 justify-content-center d-flex">
                            <img src={images.app_gallery} className="" style={{ height: "2rem", width: "auto" }} alt="..." />
                        </a>
                    </div>
                </Modal.Body>
            </Modal> */}
        </nav>

    )
}

export default Navbar