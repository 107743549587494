import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css';
import { useState, useEffect, useRef } from "react";
import { _ } from "../config/languages/i18n";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import TagManager from 'react-gtm-module'
import { set } from "date-fns";
import globals from "../config/globals/globals";
import axios from "axios";
import ShowMoreText from "react-show-more-text";
import { Helmet } from "react-helmet";

const ContactMessages = ({ setUser }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [username, setusername] = useState("")
    const [password, setPassword] = useState("")
    const [phone, setPhone] = useState("")
    const [sloganNumber, setSloganNumber] = useState("")
    const cookies = new Cookies()
    const divRef = useRef(null)
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const [contactsInfo, setContactsInfo] = useState([]);

    useEffect(() => {
        const lang = cookies.get("language")
        if (lang == 'tr') {
            const sloganNumber = (Math.floor(Math.random() * 3) + 1);
            setSloganNumber(sloganNumber)
        } else {
            const sloganNumber = (Math.floor(Math.random() * 3) + 4);
            setSloganNumber(sloganNumber)
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop
            })
        }, 200)
    }, [divRef.current])

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Contact Messages Page'
            },
            event: 'contact_messages_page'
        })
    }, [])

    useEffect(() => {
        HTTP.get('getContact').then(res => {
            if (res.data.result) {
                setContactsInfo(res.data.contact)
            }
        })
    }, []
    )

    return (
        <div >
            <Helmet>
                <title>Vidipass | {_("contact")}</title>
                <meta name="description" content={_("contact")}/>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
			</Helmet>
            <Navbar />
            <div className="container mb-5 mt-5">
                <div className="d-flex flex-lg-row flex-column rounded-2 justify-content-center">
                    <div className="card card1 col">
                        <div className="row justify-content-center">
                            <div className="col-12 ">
                                <div className="card-body row">
                                    <h1 style={{fontSize:20}} className="title">{_('contact')}</h1>
                                    <hr />
                                </div>
                                <div className="card-body">
                                    {
                                        contactsInfo.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className={`card py-3 px-2 row mb-2`}>
                                                        <span className="homeText">{item.type == 0 ? _("contact") : _("collaboration")}</span>
                                                        <hr />
                                                        <span className="text-muted">{item.type == 0 ? `${_('name_surname')} : ${item.namesurname}` : `${_('title')}:
                                                        ${item.type == 1 ? _('artist') : item.type == 2 ? _('manager') : item.type == 3 ? _('organizer') : _('venue_owner')}
                                                        `}</span>
                                                        <hr />
                                                        <span className="text-muted">{_("phone")}: {item.phone}</span>
                                                        <hr />
                                                        <span className="text-muted">{_("e_mail")}: {item.email}</span>
                                                        <hr />
                                                        <ShowMoreText
                                                            lines={3}
                                                            more={_("continue")}
                                                            less={_("close")}
                                                        >
                                                            <span style={{ whiteSpace: 'pre-line' }} className="text-muted"> {item.type == 0 ? `${_('message')}: ${item.extra} ` : `${_('information')}: ${item.extra}`}
                                                            </span>

                                                        </ShowMoreText>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="card card2 order-1 me-1 mb-1">
                        {sloganNumber == 1 ? <img src={images.slogan_1} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 2 ? <img src={images.slogan_2} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 3 ? <img src={images.slogan_3} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 4 ? <img src={images.slogan_4} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 5 ? <img src={images.slogan_5} className="w-100 rounded-1" alt="..." /> :
                            <img src={images.slogan_6} className="w-100 rounded-1" alt="..." /> 
                        }
                    </div> */}
                </div>
            </div>
            <span ref={divRef} />
            <Footer />
        </div>
    )
}

export default ContactMessages