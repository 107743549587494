import Footer from "../components/Footer";
import ".././index.css";
import 'react-phone-number-input/style.css'
import { useState, useEffect } from "react";
import { _ } from '../config/languages/i18n';
import globals from '../config/globals/globals';
import axios from "axios";
import moment from "moment";
import Loader from "../components/Loader";
import Modal from 'react-bootstrap/Modal';
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";

const TicketControl = () => {
    const [number, setNumber] = useState();
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const [loading, setLoading] = useState(false);
    const [activityId, setActivityId] = useState("");
    const [photoName, setPhotoName] = useState("");
    const [imgCreated, setImgCreated] = useState("");
    const [ticketNumber, setTicketNumber] = useState("");
    const [title, setTitle] = useState("");
    const [place, setPlace] = useState("");
    const [startTime, setStartTime] = useState("");
    const [category, setCategory] = useState("");
    const [type, setType] = useState("");
    const [price, setPrice] = useState("");
    const [seat, setSeat] = useState("");
    // const [reportModal, setReportModal] = useState(true);
    const [password, setPassword] = useState("")
    const [fullscreen, setFullscreen] = useState(true);
    const [result, setResult] = useState("");

    useEffect(() => {
        setLoading(true)
        setNumber(window.location.href.split('/')[6])
        if (number) {
            HTTP.get(`tickets/checkQrCode/${number}`).then(res => {
                setLoading(false)
                if (res.data) {
                    setActivityId(res.data.ticket.activity.id)
                    setPhotoName(res.data.ticket.activity.photo_name)
                    setImgCreated(res.data.ticket.activity.created_at)
                    setTicketNumber(res.data.ticket.ticket_number)
                    setTitle(res.data.ticket.activity.title)
                    setPlace(res.data.ticket.activity.hall.name)
                    setStartTime(res.data.ticket.activity.start_time)
                    setCategory(res.data.ticket.seat.group.name)
                    setType(res.data.ticket.current_price.name)
                    setPrice(res.data.ticket.current_price.price)
                    setSeat(res.data.ticket.seat.name)
                    setResult(res.data.result)
                } else {
                    setLoading(true);
                }
            }
            ).catch(setLoading(true));
        }
    }, [number]);

    // const reportControl = () => {
    //     if (password == 1838) {
    //         setReportModal(false)
    //     }
    // }

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Ticket Conrol Page'
            },
            event: 'ticket_control_page'
        })
    }, [])

    return (
        <div >
            <Helmet>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
			</Helmet>
            {loading ?
                <Loader />
                :
                <>
                    <div className="container py-5 mt-5">
                        {/* <Modal show={reportModal} fullscreen={fullscreen}  >
                            <Modal.Header style={{ background: "#fff" }}>
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <Modal.Title style={{ fontSize: 16 }}>
                                        <span className="homeText">{_("password")}</span>
                                    </Modal.Title>
                                </div>
                            </Modal.Header>

                            <Modal.Body >
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <div className="form-group">
                                        <label className="form-control-label text-muted">{_("password")}</label>
                                        <input type="text" value={password} onChange={(e) => setPassword(e.target.value)} placeholder={_("password")} className="form-control" />
                                    </div>
                                </div>
                                <div className="text-center mt-3" onClick={() => reportControl()}>
                                    <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                        {_("ok")}
                                    </a>
                                </div>
                            </Modal.Body>
                        </Modal> */}
                        {result ?
                            <>
                                <div className="row mb-1 m-1 py-4">
                                    <button className="btn btn-primary saloonButtonX">
                                        <span className="bi bi-check-circle-fill"></span>
                                        <div>{_("successful")}</div>
                                    </button>
                                </div>
                                <div className="row border rounded-2 mb-1 m-1 py-4">
                                    <div className="col-sm-12 col-md-4 d-flex justify-content-center align-items-center">
                                        <div className="row">
                                            <div className="col-12 text-center" >
                                                <img src={globals.imgUrl + activityId + "/thumb/" + photoName + ".png" + '?' + new Date(imgCreated).getTime()} className="figure-img img-fluid rounded-2 w-100" alt="..." />
                                            </div>
                                            <div className="col-12 text-center" >
                                                <small>{ticketNumber}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-8 d-flex text-center align-items-center">
                                        <div className="col-6 d-flex text-center align-items-center">
                                            <div className="col text-truncate text-center">
                                                <h6 className="my-0 text-muted text-center text-truncate">{title}</h6>
                                                <div className="row">
                                                    <small className="text-muted text-truncate">{place}</small>
                                                    <small className="text-muted text-truncate">{moment(startTime).format('DD.MM.YYYY')}</small>
                                                    <small className="text-muted text-truncate">{moment(startTime).format('HH:mm')}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 d-flex text-start align-items-center text-truncate">
                                            <div className="row text-truncate text-center">
                                                <small className="text-muted text-truncate">{_("category")}: {category}</small>
                                                <small className="text-muted text-truncate">{_("type")}: {type}</small>
                                                <small className="text-muted text-truncate">{_("fee")}: {price} TRY</small>
                                                <small className="text-muted text-truncate">{_("armchair")}: {seat}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="row mb-1 m-1 py-4">
                                    <button className="btn btn-primary saloonButtonX">
                                        <span className="bi bi-x-circle-fill"></span>
                                        <div>{_("unsuccessful")}</div>
                                    </button>
                                </div>
                                <div className="row border rounded-2 mb-1 m-1 py-4">
                                    <div className="text-center homeText">{_("no_ticket_found")}</div>
                                </div>
                            </>
                        }
                    </div>
                    <Footer />
                </>
            }
        </div>
    )
}

export default TicketControl