import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css'
import React, { useState, useEffect, useRef } from "react";
import { _ } from "../config/languages/i18n";
import { Cookies } from "react-cookie";
import Loader from "../components/Loader";
import TagManager from 'react-gtm-module'
import { useDispatch } from "react-redux";
import { saveContact } from "../redux/actions/auth";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import globals from "../config/globals/globals";
import { Helmet } from "react-helmet";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import AddActivity from "./AddActivity";
import DataTable from 'react-data-table-component';
import moment from "moment";



const Activities = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const [activityEditModal, setActivityEditModal] = useState(false);
    const [activityId, setActivityId] = useState("");
    const [activityTitle, setActivityTitle]= useState("")
    const [activityDescription, setActivityDescription]= useState("")
    const [activityComment, setActivityComment]= useState("")
    const [metaDescription, setMetaDescripton]= useState("")
    const [metaKeywords, setMetaKeywords] = useState([])
    const [activityPlace, setActivityPlace]= useState("")
    const [activityStartTime, setActivityStartTime] = useState("")
    const [activityEndTime, setActivityEndTime] = useState("")
    const [ip, setIp] = useState('')
    const [device, setDevice] = useState('')
    const [utcLog, setUtcLog] = useState('')
    const [userId, setUserId] = useState("");
    const formData = new FormData();

    const [editorModal, setEditorModal] = useState(false);
    const [metaTag, setMetaTag] = useState('')
    const [activityStatusModal, setActivityStatusModal] = useState(false);
    const [activityStatus, setActivityStatus] = useState("");
    const [activityStatusStopModal, setActivityStatusStopModal] = useState(false);
    const [activityStatusCancelModal, setActivityStatusCancelModal] = useState(false);
    const [number, setNumber] = useState('');

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        if (getuser?.result) {
            setUserId(getuser.user.id);
        }
    }, []);
    
    const [date, setDate] = useState([
		{
			startDate: new Date(),
			endDate: new Date(2033, 11, 31),
			key: "selection"
		}]);
        const [activity, setActivity] = useState([]);

    useEffect(() => {
		const start = moment(date[0].startDate).format("YYYY-MM-DD");
		const end = moment(date[0].endDate)
			.add(1, "year")
			.format("YYYY-MM-DD");
		setLoading(true);
		HTTP.get(`activities/getAllLogout?term&start=${start}&end=${end}&city&cats`)
			.then((res) => {

				setLoading(false);
				if (res.data.result) {
					setActivity(res.data.activities.data)
				} else {
					setLoading(false);
				}
			})
			.catch(setLoading(true));
	}, []);

    useEffect(()=>{
		setDevice(navigator.userAgent)
	},[])

    useEffect(()=>{
		setUtcLog(new Date().getTimezoneOffset()/-60)
	},[])

	useEffect(()=>{
		HTTP.get('https://api.ipify.org?format=json')
		.then(
			res=>{
				setIp(res.data.ip)
			}
		)
	},[])

    const columns = [
        {
            name: _('photo'),
            grow:0.5,
            cell: row => <img height="50px" width="100px" src={globals.imgUrl + row.id + "/thumb/" + row.photo_name + ".png" + '?' + new Date(row.created_at).getTime()} />,
            
        },
        {
            name: _('activity_name'),
            selector: row => row.title,
            sortable: true,
        },
        {
            name: _('start_time'),
            selector: row => row.start_time,
            sortable: true,
        },
        {
            name: _('place'),
            selector: row => row.hall.name,
            sortable: true,
        },
        {
            name: _('event_detail'),
            button: true,
            selector: row => (
                <a className="homeText text-decoration-none" href={globals.url + 'a/' + row.number} rel="noopener noreferrer">
                    {row.number}
                </a>
            )
        },
        {
            name: _('actions'),
            button: true,
            cell: row => (
                <span className="d-flex">
                    <button style={{border:'none'}} className="mx-1 p-1 saloonButton rounded" onClick={()=>window.location.href=globals.url + 'a/activityreport/' + row.number} target="_blank" rel="noopener noreferrer" title={_('activity_report')}>
                        <span style={{color:"#FFFFFF"}} className="bi bi-eye m-1"></span>
                    </button>
                    <button style={{border:'none'}} className="mx-1 p-1 saloonButton rounded" onClick={()=>activityEdit(row)} target="_blank" rel="noopener noreferrer" title={_('activity_edit')}>
                        <span style={{color:"#FFFFFF"}} class="bi bi-pencil m-1"></span>
                    </button>
                    <button style={{border:'none'}} className="mx-1 p-1 saloonButton rounded" onClick={()=>activityEditor(row)} target="_blank" rel="noopener noreferrer" title={_('activity_packages')}>
                        <span style={{color:"#FFFFFF"}} class="bi bi-chat-square-dots m-1"></span> 
                    </button>
                    <button style={{border:'none'}} className="mx-1 p-1 saloonButton rounded" onClick={()=>changeActivityStatusEdit(row)} target="_blank" rel="noopener noreferrer" title={_('activity_control')}>
                        <span style={{color:"#FFFFFF"}} class="bi bi-sliders m-1"></span>
                    </button>
                
                </span>
                
            )
           
        },       
    ];
    
const activityEdit = (item) =>{
    setActivityId(item.id)
    setActivityTitle(item.title)
    setActivityDescription(item.description)
    setActivityComment(item.comment)
    setMetaDescripton(item.meta_description)
    if (item.meta_keywords !== null && item.meta_keywords !== '') {
        setMetaKeywords(JSON.parse(item.meta_keywords));
      }
    setActivityPlace(item.place.id)
    setActivityStartTime(new Date(item.start_time))
    setActivityEndTime(new Date(item.end_time))
    setActivityEditModal(true)
}

const activityEditor = (item) =>{
    setActivityId(item.id)
    setActivityTitle(item.title)
    setActivityDescription(item.description)
    setActivityComment(item.comment)
    setMetaDescripton(item.meta_description)
    if (item.meta_keywords !== null && item.meta_keywords !== '') {
        setMetaKeywords(JSON.parse(item.meta_keywords));
      }
    setActivityPlace(item.place.id)
    setActivityStartTime(new Date(item.start_time))
    setActivityEndTime(new Date(item.end_time))
    setEditorModal(true)
}

const saveMetaTag = () =>{
    if(metaKeywords.length < 9){
        if(metaTag != ''){
            if(metaKeywords.indexOf(metaTag)== -1){
                setMetaKeywords(data=>[...data, (metaTag)])
            }
            setMetaTag('')
        }
    }else{
        setMetaTag('')
    }
}
const removeMetaTag = (data) =>{
    setMetaKeywords(metaKeywords.filter(item=>item!==data))
}
const tagList = () =>{
    return metaKeywords.map((data)=>{
        return(
            <div class="col d-flex justify-content-center">
                <div key={data} class=" text-decoration-none btn btn-primary saloonButton rounded-2">{data}</div>
                <button  onClick={() => removeMetaTag(data)} className="btn btn-secondary bi bi-x-circle-fill"/>
            </div>
        )
    })
}

const sendEditorPanel = () =>{
    formData.append("id", activityId);
    formData.append("activity_title", activityTitle);
    formData.append("description", activityDescription);
    formData.append("comment", activityComment);
    formData.append("meta_description", metaDescription);
    formData.append("meta_keywords", JSON.stringify(metaKeywords));
    formData.append("place_id", activityPlace);
    formData.append("start_time", moment(activityStartTime).format("DD.MM.YYYY HH:mm"));
    formData.append("end_time", moment(activityEndTime).format("DD.MM.YYYY HH:mm"));
    formData.append("ip_address", ip);
    formData.append("device_name", device);
    formData.append("utc", utcLog);
    formData.append("userId", userId);

    HTTP.post("/activities/update", formData)
        .then((res) => {
            console.log(res)
            
            if (res.data.result) {
                
                    setEditorModal(false);
                    window.location.reload();
                
            } else {
                setLoading(true);
            }
        })
        .catch(setLoading(true));

}

const changeActivityStatus = (val) => {
    formData.append("number", number);
    formData.append("activity_status", val);
    formData.append("ip_address", ip);
    formData.append("device_name", device);
    formData.append("utc", utcLog);
    formData.append("userId", userId);

    setLoading(true);
    HTTP.post("/changeActivityStatus", formData)
        .then((res) => {
            if (res.data.result) {
                if (res.data.activity.deleted_at) {
                    setActivityStatusModal(false);
                    setLoading(false);
                    setActivityStatusCancelModal(false)
                    setActivityStatusStopModal(false)
                    window.location.reload();
                   
                } else {
                    setActivityStatusModal(false);
                    setLoading(false);
                    setActivityStatusCancelModal(false)
                    setActivityStatusStopModal(false)
                    window.location.reload();
                    
                }
            } else {
                setLoading(true);
            }
        })
        .catch(setLoading(true));
};

const activityChangeStatus = (val) => {
    if (val != 2) {
        setActivityStatusStopModal(true);
    } else {
        setActivityStatusCancelModal(true);
    }
};

const changeActivityStatusEdit = (item) =>{
    setNumber(item.number)
    setActivityStatus(item.activity_status);
    setActivityStatusModal(true)
}


    return (
        <div >
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Navbar />
                    <div className="container mt-5 mb-5">
                        <div className="d-flex justify-content-center card">
                        <div className="col-sm-12 col-md-4 mb-2">
										<input
											type="search"
											className="form-control bg-white headerInput text-muted"
											placeholder={_("search")}
											aria-label={_("search")}
											value={searchTerm}
											onChange={(e) => setSearchTerm(e.target.value)}>
										</input>
									</div>
                            <DataTable
                                columns={columns}
                                responsive
                                pagination
                                paginationPerPage={100}
                                paginationRowsPerPageOptions={[100,500,1000,5000,10000]}
                                data={activity.filter((item) => {
                                    if (searchTerm === "") {
                                      return item;
                                    } else if (
                                      item.title.toLowerCase().includes(searchTerm.toLowerCase())
                                      ||
                                      item.hall.name.toLowerCase().includes(searchTerm.toLowerCase())
                                    ) {
                                      return item;
                                    }
                                  })}
                             
                            />
                        </div> 
                    </div>
                    <Modal
                            show={activityEditModal}
                            onHide={() => setActivityEditModal(false)}
                            
                        >
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("activity_edit")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-sm-12 card px-2">
                                <form onSubmit={(e) => {
                                            e.preventDefault();

                                            formData.append("id", activityId);
                                            formData.append("activity_title", activityTitle);
                                            formData.append("description", activityDescription);
                                            formData.append("comment", activityComment);
                                            formData.append("meta_description", metaDescription);
                                            formData.append("meta_keywords", JSON.stringify(metaKeywords));
                                            formData.append("place_id", activityPlace);
                                            formData.append("start_time", moment(activityStartTime).format("DD.MM.YYYY HH:mm"));
                                            formData.append("end_time", moment(activityEndTime).format("DD.MM.YYYY HH:mm"));
                                            formData.append("ip_address", ip);
                                            formData.append("device_name", device);
                                            formData.append("utc", utcLog);
                                            formData.append("userId", userId);
                                    
                                            HTTP.post("/activities/update", formData)
                                                .then((res) => {
                                                   
                                                    if (res.data.result) {
                                                        
                                                            setActivityEditModal(false);
                                                            window.location.reload();
                                                        
                                                    } else {
                                                        setLoading(true);
                                                    }
                                                })
                                                .catch(setLoading(true));
                                            
                                            
                                        }} >
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("activity_title")}</label>
                                                <input type="text" value={activityTitle} onChange={(e) => setActivityTitle(e.target.value)} className="form-control" />
                                            </div>

                                            <div className="form-group mt-1">
                                                <label className="form-control-label text-muted">{_("place")} ( id )</label>
                                                <input type="number" value={activityPlace} onChange={(e) => setActivityPlace(e.target.value)} className="form-control bg-white" />
                                            </div>

                                            <div className="row mt-2 text-center">

                                            <div className="form-group col-6">
                                                <div className="text-start">

                                                <label className="form-control-label text-muted">{_("start_time")}</label>
                                                </div>
                                                <div>
                                               
                                                <DatePicker
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={5}
                                                dateFormat="dd.MM.yyyy / HH:mm"
                                                className="form-control text-start"
                                                selected={activityStartTime} 
                                                onChange={(e) => setActivityStartTime(e)}                                                 
                                                />
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <div className="text-start">
                                                    <label className="form-control-label text-muted">{_("end_time")}</label>
                                                </div>
                                                <div>
                                                    <DatePicker
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={5}
                                                    dateFormat="dd.MM.yyyy / HH:mm"
                                                    className="form-control  text-start"
                                                    selected={activityEndTime} 
                                                    onChange={(e) => setActivityEndTime(e)} 
                                                    />
                                                </div>
                                            </div>
                                            </div>
                                            <div className="form-group mb-2 mt-2">
                                                <label className="form-control-label text-muted">{_("description")}</label>
                                                <textarea rows="4" cols="50" value={activityDescription} onChange={(e) => setActivityDescription(e.target.value)}></textarea>
                                            </div>
                                            {/* <div className="form-group mb-2 mt-2">
                                                <label className="form-control-label text-muted">{_("editor_comment")}</label>
                                                <textarea rows="4" cols="50" value={activityComment} onChange={(e) => setActivityComment(e.target.value)}></textarea>
                                            </div> */}
                                            <div className="row justify-content-center my-3 px-3">
                                                <button className="text-decoration-none btn btn-primary cardButton col-6" type="submit">{_("send")}</button>
                                            </div>
                                        </form>
                                </div>
                            </Modal.Body>
                        </Modal>
                    
                        <Modal
                            show={editorModal}
                            onHide={() => setEditorModal(false)}
                            
                        >
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("activity_packages")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-sm-12 card px-2">
                                    <div className="form-group mb-2 mt-2">
                                        <label className="form-control-label text-muted">{_("editor_comment")}</label>
                                        <textarea rows="4" cols="50" value={activityComment} onChange={(e) => setActivityComment(e.target.value.trimStart())}></textarea>
                                    </div>
                                    <div className="form-group mb-2 mt-2">
                                        <label className="form-control-label text-muted">{_("meta_description")}</label>
                                        <textarea rows="4" cols="50" value={metaDescription} onChange={(e) => setMetaDescripton(e.target.value.trimStart())}></textarea>
                                    </div>
                                    <div className="col-sm-12">
                                        <label className="form-control-label text-muted">{_("meta_keyword")}</label>
                                        <div className="input-group">
                                            <input type="text" value={metaTag.trim()} onChange={(e) =>setMetaTag(e.target.value)} className="form-control text-muted bg-white" />
                                            <button  onClick={() => saveMetaTag()} className="btn btn-secondary text-center bg-secondary">{_('add')}</button>
                                        </div>
                                        <div className="row grid gap-2 py-2 d-flex justify-content-center">
                                            {tagList()}
                                        </div>
                                    </div>
                                    <div onClick={()=>sendEditorPanel()} className="row justify-content-center my-3 px-3">
                                        <button className="text-decoration-none btn btn-primary cardButton col-6">{_("send")}</button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal
                            show={activityStatusCancelModal}
                            onHide={() => setActivityStatusCancelModal(false)}
                        >
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("cancel_activity")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <h6 className="homeText">{_("sure")}</h6>
                                </div>
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <div className="row">
                                        <div
                                            className="col text-end"
                                            onClick={() => changeActivityStatus(2)}
                                        >
                                            <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                <i className="bi bi-check-circle-fill"></i>
                                            </a>
                                        </div>
                                        <div
                                            className="col text-end"
                                            onClick={() => setActivityStatusCancelModal(false)}
                                        >
                                            <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                <i className="bi bi-x-circle-fill"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal
                            show={activityStatusStopModal}
                            onHide={() => setActivityStatusStopModal(false)}
                        >
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    {activityStatus == 0 ? (
                                        <span className="homeText">
                                            {_("stop_selling_tickets_for_event")}
                                        </span>
                                    ) : (
                                        <span className="homeText">
                                            {_("continue_selling_tickets_for_evet")}
                                        </span>
                                    )}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <h6 className="homeText">{_("sure")}</h6>
                                </div>
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <div className="row">
                                        <div
                                            className="col text-end"
                                            onClick={
                                                activityStatus == 0
                                                    ? () => changeActivityStatus(1)
                                                    : () => changeActivityStatus(0)
                                            }
                                        >
                                            <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                <i className="bi bi-check-circle-fill"></i>
                                            </a>
                                        </div>
                                        <div
                                            className="col text-end"
                                            onClick={() => setActivityStatusStopModal(false)}
                                        >
                                            <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                <i className="bi bi-x-circle-fill"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal
                            show={activityStatusModal}
                            onHide={() => setActivityStatusModal(false)}
                        >
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("activity_control")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-sm-12 card">
                                    <div className="m-2 row">
                                        {activityStatus == 0 ? (
                                            <a
                                                onClick={() => activityChangeStatus(1)}
                                                className="text-decoration-none d-flex justify-content-center align-items-center btn btn-primary saloonButton rounded-2 col me-1"
                                            >
                                                {_("stop_selling_tickets_for_event")}
                                            </a>
                                        ) : (
                                            <a
                                                onClick={() => activityChangeStatus(0)}
                                                className="text-decoration-none d-flex justify-content-center align-items-center btn btn-primary saloonButton rounded-2 col ms-1"
                                            >
                                                {_("continue_selling_tickets_for_evet")}
                                            </a>
                                        )}

                                        <a
                                            onClick={() => activityChangeStatus(2)}
                                            className="text-decoration-none d-flex justify-content-center align-items-center btn btn-primary saloonButton rounded-2 col ms-1"
                                        >
                                            {_("cancel_activity")}
                                        </a>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    

                </>
            )}
            <Footer />
        </div>
    )
}

export default Activities