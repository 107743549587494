import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css'
import { useState, useEffect, useRef } from "react";
import { _ } from '../config/languages/i18n';
import { useDispatch } from "react-redux";
import { activationAction } from "../redux/actions/auth";
import { codeSendAgainAction } from "../redux/actions/auth";
import { toast } from "react-toastify";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { Cookies } from "react-cookie";
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";
import globals from '../config/globals/globals';
import axios from "axios";

const Activation = () => {
    const [code, setCode] = useState("");
    const [codeStart, setCodeStart] = useState("");
    const [codeEnd, setCodeEnd] = useState("");
    const dispatch = useDispatch();
    const [user, setUser] = useState("");
    const [counter, setCounter] = useState("");
    const formData = new FormData();
    const [sloganNumber, setSloganNumber] = useState("")
    const cookies = new Cookies()
    const divRef = useRef(null)
    const lang = cookies.get("language")
    const [ip, setIp] = useState('')
    const [device, setDevice] = useState('')
    const [utc, setUtc] = useState('')
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const codeStartRef = useRef(null);
    const codeEndRef = useRef(null);
    

    useEffect(()=>{
		setDevice(navigator.userAgent)
	},[])

    useEffect(()=>{
		setUtc(new Date().getTimezoneOffset()/-60)
	},[])

	useEffect(()=>{
		HTTP.get('https://api.ipify.org?format=json')
		.then(
			res=>{
				setIp(res.data.ip)
			}
		)
	},[])

    useEffect(() => {
        if (lang == 'tr') {
            const sloganNumber = (Math.floor(Math.random() * 3) + 1);
            setSloganNumber(sloganNumber)
        } else {
            const sloganNumber = (Math.floor(Math.random() * 3) + 4);
            setSloganNumber(sloganNumber)
        }
    }, []);

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem('auth'));
        if (getuser) {
            setUser(getuser.user);
            if (getuser.user.usabilitySeconds) {
                setCounter(getuser.user.usabilitySeconds);
            } else {
                setCounter(120);
            }
        }
    }, []);

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop
            })
        }, 200)
    }, [divRef.current])

    const handleCodeStart = (e) => {
        if (e.length < 3) {
            setCodeStart(e)
        }

        if (e.length === 2) {
            codeEndRef.current.focus();
        }
    };

    const handleCodeEnd = (e) => {
        if (e.length < 3) {
            return setCodeEnd(e)
        };
    };

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Activation Page'
            },
            event: 'activation_page'
        })
    }, [])

    const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

    return (
        <div >
            <Helmet>
                <title>Vidipass | {_("activation_code")}</title>
                <meta name="description" content={_("activation_code")}/>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
			</Helmet>
            <Navbar />
            <div className="container mt-5">

                <div className="card col-md-6 offset-md-3">
                    <div className="row justify-content-center my-auto">
                        <div className="col-md-8 col-10">
                            {
                                user.email ?
                                    <div className="row justify-content-center mb-3 mt-3">
                                        <p className="d-flex justify-content-center" style={{ color: "#933CAC" }} >{_("enter_activation_code_for_email")}</p>
                                        <p className="d-flex justify-content-center" style={{ color: "#933CAC" }} >({user.email})</p>
                                    </div>
                                    :
                                    <div className="row justify-content-center mb-3 mt-3">
                                        <p className="d-flex justify-content-center" style={{ color: "#933CAC" }} >{_("enter_activation_code_for_phone")}</p>
                                        <p className="d-flex justify-content-center" style={{ color: "#933CAC" }} >({user.phone})</p>
                                    </div>
                            }

                            <div className="row justify-content-center mb-3 mt-3">
                                <img width={'auto'} height={'auto'} style={{ width: 70 }} className="mb-2" src={images.activation_code_icon} alt="activation code" />
                                <h1 className="d-flex justify-content-center" style={{ color: "#933CAC", fontSize:16 }} type="submit">{_("activation_code")}</h1>
                            </div>
                            {counter > 0 ?
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    const userId = user.id;

                                    if (codeStart.length == 2 && codeEnd.length == 2) {
                                        formData.append("code", codeStart + codeEnd);
                                        formData.append("userId", userId);
                                        formData.append("lang", lang);
                                        formData.append("ip_address", ip);
                                        formData.append("device_name", device);
                                        formData.append("utc", utc);
                                        dispatch(activationAction(formData));
                                    } else {
                                        toast(_('password_must_be_four_characters'), {
                                            position: "top-right",
                                            autoClose: 3000,
                                            hideProgressBar: false,
                                            closeOnClick: false,
                                            pauseOnHover: false,
                                            draggable: false,
                                            progress: undefined,
                                            theme: "light",
                                            closeButton: false,
                                            type: "error"
                                        })
                                    }
                                }}>
                                    <div className="form-group">
                                        <label className="form-control-label text-muted">{_("activation_code")}</label>
                                        <div className="d-flex justify-content-center align-items-center flex-row form-control headerInput bg-white">
                                            <span type="text" className="me-1" autofocus="">v</span>
                                            <span type="text" className="me-1" autofocus="">-</span>
                                            <input value={codeStart} onChange={(e) => handleCodeStart(e.target.value)} style={{ width: 50 }} type="text" className="form-control me-1" maxLength={2} ref={codeStartRef} />
                                            <span type="text" className="me-1" autofocus="">-</span>
                                            <input value={codeEnd} onChange={(e) => handleCodeEnd(e.target.value)} style={{ width: 50 }} type="text" className="form-control" maxLength={2} ref={codeEndRef} />
                                        </div>
                                    </div>
                                    <div onClick={() =>  gtmTag('approve_button')} className="row justify-content-center my-3 px-3">
                                        <button disabled={!codeStart || !codeEnd} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" type="submit">{_("approve")}</button>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={120}
                                            colors={['#2E81D2', '#933CAC', '#D31095', '#EC008C']}
                                            size={100}
                                            colorsTime={[120, 80, 40, 0]}
                                        >
                                            {({ remainingTime }) => remainingTime}
                                        </CountdownCircleTimer>
                                    </div>
                                </form>
                                :
                                <form onSubmit={(e) => {
                                    e.preventDefault()
                                    const email = user.email;
                                    const phone = user.phone;
                                    const phone_number = user.phone_number;
                                    const country_code = user.country_code;
                                    const type = user.activation_type;
                                    const password_reset_or_register_type = 1;
                                    formData.append("email", email);
                                    formData.append("phone", phone);
                                    formData.append("phone_number", phone_number);
                                    formData.append("country_code", country_code);
                                    formData.append("type", type);
                                    formData.append("password_reset_or_register_type", password_reset_or_register_type);
                                    formData.append("lang", lang);
                                    formData.append("ip_address", ip);
                                    formData.append("device_name", device);
                                    formData.append("utc", utc);
                                    dispatch(codeSendAgainAction(formData));
                                }}>
                                    <div onClick={() =>  gtmTag('resend_code_button_activation')} className="row justify-content-center my-3 px-3">
                                        <button className="text-decoration-none btn btn-primary cardButton rounded-2 col-8" type="submit">{_("resend_code")}</button>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>

                </div>
                {/* <div className="card card2 order-1 me-1 mb-1">
                        {sloganNumber == 1 ? <img src={images.slogan_1} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 2 ? <img src={images.slogan_2} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 3 ? <img src={images.slogan_3} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 4 ? <img src={images.slogan_4} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 5 ? <img src={images.slogan_5} className="w-100 rounded-1" alt="..." /> :
                            <img src={images.slogan_6} className="w-100 rounded-1" alt="..." /> 
                        }
                    </div> */}
            </div>
            <span ref={divRef} />
            <Footer />
        </div>
    )
}

export default Activation