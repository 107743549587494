import ".././index.css";
import { ThreeCircles } from 'react-loader-spinner';

const Loader = () => {
    return (
        <div className="overlay">
            <div className="d-flex justify-content-center">
                <div className="position-absolute w-25 top-50 d-flex justify-content-center p-3">
                    <ThreeCircles
                        height="100"
                        width="100"
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass={{}}
                        visible={true}
                        ariaLabel="three-circles-rotating"
                        outerCircleColor="#933CAC"
                        innerCircleColor="#EC008C"
                        middleCircleColor="#00A1E4"
                    />
                </div>
            </div>
        </div>
    )
}
export default Loader